
import { Component, Vue } from "vue-property-decorator";
import languages from "../../config/language.config";
@Component({
  components: {},
})
export default class LanguageSwitcher extends Vue {
  languages = languages;

  changeLocale(locale: string): void {
    localStorage.locale = locale;
  }
}
