
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class CookieComponent extends Vue {
  cookie_toggle = false;

  closeCookies(): void {
    localStorage.setItem("cookie", "true");
    this.cookie_toggle = false;
  }

  mounted(): void {
    if (localStorage.locale == null || localStorage.locale == undefined) {
      localStorage.setItem("locale", String(this.$i18n.locale));
    }
  }
  created(): void {
    const cookie = localStorage.getItem("cookie");
    this.cookie_toggle = cookie ? false : true;
  }
}
