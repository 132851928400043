export default [
  {
    value: "ru",
    text: "Русский",
  },
  // {
  //   value: "en",
  //   text: "English",
  // },
];
