
import { Component, Vue, Watch } from "vue-property-decorator";
import links_config from "../../config/main-links.config";
import DropDownComponent from "../../components/_shared/dropdown-component.vue";
interface ILinks {
  name: string;
  url: string;
}
@Component({
  components: {
    "dropdown-component": DropDownComponent,
  },
})
export default class HeaderComponent extends Vue {
  links: ILinks[] = [];
  dropdownLinks: ILinks[] = [];
  isHovered = false;

  Hovered(hovered: boolean): void {
    this.isHovered = hovered;
  }

  @Watch("$i18n.locale")
  getLinks(): void {
    this.links = [];
    for (let i = 0; i < links_config.length; i++) {
      this.links.push({
        name: String(this.$i18n.t(`links.main.${links_config[i]}`)),
        url: links_config[i] == "home" ? "/" : links_config[i],
      });
    }
  }

  mounted(): void {
    this.getLinks();
  }
}
