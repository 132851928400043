import { repairSpace } from "./check_url";
import host from "@/config/host.config.json";

export function getWebImage(name: string, folder: string): string {
  let image = `${host.straightPictures}/pics/${folder}/${name}`;
  if (image) {
    image = repairSpace(image);
  }
  return image;
}

export function getImage(
  img_name: string | null,
  folder: string | null
): string | undefined {
  let image: string | undefined;
  if (img_name != null) {
    try {
      if (folder != null && folder != "null") {
        image = require(`@/assets/images/${folder}/${img_name}`);
      } else {
        image = require(`@/assets/images/${img_name}`);
      }
      if (image) {
        image = repairSpace(image);
      }
    } catch (e) {
      console.log(e);
    }
  }
  return image;
}
