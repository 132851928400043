
import { Component, Vue } from "vue-property-decorator";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

// import { getImage } from "../services/getImage";
@Component({
  components: {
    Hooper,
    Slide,
  },
})
export default class SignDemo extends Vue {
  // getImage(name: string, folder: string | null): string | null {
  //   return getImage(name, folder);
  // }
  date = new Date();

  exhibition = [
    {
      name: "WorldCheese Moscow",
      description: "Cheese festival",
      plase: "Atrium Paris",
      date: `11.01.${this.date.getFullYear()}`,
    },
    {
      name: "ARTCon",
      description: "Exhibition of graffiti",
      plase: "Atrium Berlin",
      date: `15.01.${this.date.getFullYear()}`,
    },
    {
      name: "MosAgroTech",
      description: "Agro-industrial exhibition",
      plase: "Atrium Moscow",
      date: `24.01.${this.date.getFullYear()}`,
    },
    {
      name: "ZooExpo 2023",
      description: "International exhibition of the pet industry",
      plase: "Atrium Paris",
      date: `30.01.${this.date.getFullYear()}`,
    },
    {
      name: "МИР ДЕНТАЛ 12",
      description: "12 международный стоматологический форум",
      plase: "Atrium Pekin",
      date: `05.02.${this.date.getFullYear()}`,
    },
    {
      name: "HotelExpo",
      description: "Выставка индустрии гостеприимства.",
      plase: "Atrium Moscow",
      date: `26.02.${this.date.getFullYear()}`,
    },
  ];
}
