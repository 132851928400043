interface ILink {
  name: string;
  icon: string;
}
const links: ILink[] = [
  {
    name: "filoxenia_tv",
    icon: "tv",
  },
  {
    name: "dangis",
    icon: "globe",
  },
  {
    name: "cinema",
    icon: "camera-video",
  },
  {
    name: "internet",
    icon: "wifi",
  },
  {
    name: "sign",
    icon: "info-square",
  },
  {
    name: "mobile",
    icon: "phone",
  },
  {
    name: "pad",
    icon: "tablet-landscape",
  },
  {
    name: "tv",
    icon: "pip",
  },
];
export default links;
