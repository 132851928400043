
import { Component, Watch, Vue } from "vue-property-decorator";
import dropdown_links_config from "../../config/dropdown-links.config";
interface IDropdownLinks {
  name: string;
  url: string;
  icons: string;
}
@Component({})
export default class DropdownComponent extends Vue {
  dropdownLinks: IDropdownLinks[] = [];
  show = false;

  isHoveredFiloxeniaTv = false;
  isHoveredDANGis = false;
  isHoveredCinema = false;
  isHoveredInternet = false;
  isHoveredSign = false;
  isHoveredMobile = false;
  isHoveredPad = false;
  isHoveredSatilite = false;

  HoveredFiloxeniaTv(hovered: boolean): void {
    this.isHoveredFiloxeniaTv = hovered;
  }
  HoveredDANGis(hovered: boolean): void {
    this.isHoveredDANGis = hovered;
  }
  HoveredCinema(hovered: boolean): void {
    this.isHoveredCinema = hovered;
  }
  HoveredInternet(hovered: boolean): void {
    this.isHoveredInternet = hovered;
  }
  HoveredSign(hovered: boolean): void {
    this.isHoveredSign = hovered;
  }
  HoveredMobile(hovered: boolean): void {
    this.isHoveredMobile = hovered;
  }
  HoveredPad(hovered: boolean): void {
    this.isHoveredPad = hovered;
  }
  HoveredSatilite(hovered: boolean): void {
    this.isHoveredSatilite = hovered;
  }

  @Watch("$i18n.locale")
  async getdropdownLinks(): Promise<void> {
    this.dropdownLinks = [];
    for (let i = 0; i < dropdown_links_config.length; i++) {
      this.dropdownLinks.push({
        name: String(
          this.$i18n.t(`links.dropdown.${dropdown_links_config[i].name}`)
        ),
        url: dropdown_links_config[i].name,
        icons: dropdown_links_config[i].icon,
      });
    }
  }

  mounted(): void {
    this.getdropdownLinks().then(() => {
      this.show = true;
    });
  }
}
