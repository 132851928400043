
import { Component, Vue } from "vue-property-decorator";
import LanguageSwitcher from "./language-switcher.vue";
import NavPolicity from "./nav-polisity.vue";
@Component({
  components: {
    LanguageSwitcher,
    NavPolicity,
  },
})
export default class FooterComponent extends Vue {
  get getDate(): number {
    return new Date().getFullYear();
  }
}
