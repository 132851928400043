
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class QuickScroll extends Vue {
  scrolling = false;

  scrollTo(): void {
    window.scrollTo(0, 0);
  }

  scrollPosition(): void {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    if (scrollY > 400) {
      this.scrolling = true;
    } else {
      this.scrolling = false;
    }
  }

  mounted(): void {
    window.addEventListener("scroll", this.scrollPosition);
  }

  destroyed(): void {
    window.removeEventListener("scroll", this.scrollPosition);
  }
}
