import i18n from "@/i18n";
import lang from "@/config/languags.config.json";
import { IWkiLocale } from "@/@types/wiki/wiki-object";

export function curentIndex(arr: Array<any>): number {
  for (let i = 0; i < arr.length; i++) {
    if (i18n.locale == arr[i].lang) {
      return i;
    }
  }
  for (let i = 0; i < arr[i].length; i++) {
    if (arr[i].lang == "en") {
      return i;
    }
  }
  for (let i = 0; i < arr[i].length; i++) {
    if (lang.preferred_language == arr[i].lang) {
      return i;
    }
  }
  for (let i = 0; i < arr[i].length; i++) {
    if (i18n.fallbackLocale == arr[i].lang) {
      return i;
    }
  }

  return 0;
}

export function givenLocale(arr: IWkiLocale[], locale: string): number | null {
  for (let i = 0; i < arr.length; i++) {
    if (locale == arr[i].lang) {
      return i;
    }
  }
  return null;
}
